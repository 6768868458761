import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import style from './KeapBrandLogoPropertyEditor.scss';
import KeapBrandLogoManager from './KeapBrandLogoManager';
import { requestBrandLogos } from './keap-brand-logo-requests';
import { intl } from '@/shared/intl';
import logoPlaceholder from '@/icons/logo_placeholder.svg';

export const KeapBrandLogoPropertyEditor = ({ values, updateValue }) => {
    const { logoTool } = values;
    const [showLogoManager, setShowlogoManager] = useState(false);
    const [brandLogos, setBrandLogos] = useState([]);
    const [selectedLogo, setSelectedLogo] = useState(null);

    const handleBrandLogoManager = () => {
        setShowlogoManager(!showLogoManager);
    };

    const handleLogoSelect = (logo) => {
        setSelectedLogo(logo);
        updateValue({ id: logo.id, url:logo.url });
        setShowlogoManager(false);
    };

    useEffect(() => {
        fetchBrandLogos();
    }, []);

    const fetchBrandLogos = async () => {
        const logos = await requestBrandLogos();
        const primaryLogo = logos.find((logo) => logo.primary);

        setBrandLogos(logos);

        if (!logoTool.url) {
            setSelectedLogo(primaryLogo);
            updateValue({ id: primaryLogo.id, url:primaryLogo.url });
        } else {
            setSelectedLogo(logoTool);
            updateValue({ id: logoTool.id, url:logoTool.url });
        }
    };

    return (
        <>
            <div className={style.panelSection}>
                <Label className={style.labelStyle}>{intl.get('tool.brand.logo.label', { defaultMessage: 'Logo' })}</Label>
                <Button className={style.styledButton} size='sm' color="primary" onClick={handleBrandLogoManager}>{intl.get('tool.brand.more.logo.label', { defaultMessage: 'More Logos' })}</Button>
            </div>
            <Row className={style.logoEditorImageContainer}>
                <Col className={style.logoImageCol}>
                    {selectedLogo ? (
                        <img className={style.logoImage} src={selectedLogo.url} alt="brand-logo" />
                    ) : (
                        <div className={style.logoImage}>
                            <svg viewBox={logoPlaceholder.viewBox}>
                                <use xlinkHref={`#${logoPlaceholder.id}`} />
                            </svg>
                        </div>
                    )}
                </Col>
            </Row>
            {showLogoManager && brandLogos ? (
                <KeapBrandLogoManager logos={brandLogos} onLogoSelect={handleLogoSelect} onClose={handleBrandLogoManager} />
            ) : null}
        </>
    );
};

export default KeapBrandLogoPropertyEditor;
